import React from 'react';
import styles from './AboutHeroSection.module.css';
import heroImage from '../../assets/images/9527460-removebg.png';

const AboutHeroSection = () => {
  return (
    <div className={styles.heroSection}>
      <div className={styles.heroContent}>
        <h1 className={styles.heading}>We're realtors born out of a passion for customer satisfaction</h1>
        <button className={styles.button}>Get Started</button>
      </div>
      <div className={styles.heroImage}>
        <img src={heroImage} alt="Hero" />
      </div>
    </div>
  );
};

export default AboutHeroSection;