import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Feature.module.css";
import house1 from "../../assets/images/images/hazaripahad 04 revised.jpg";
import house2 from "../../assets/images/images/PHOTO-2024-04-01-17-55-24.jpg";
import house3 from "../../assets/images/images/milind_sonarkar_option2-02_revised04-08-23.jpg";

const Feature = () => {
  const numBubbles = 30; // Number of bubbles
  const bubbleSize = 20; // Bubble diameter in pixels

  const createRandomBubble = () => {
    const left = Math.random() * 100 + "vw";
    const top = Math.random() * 100 + "vh";
    const size = Math.random() * bubbleSize + "px";

    return (
      <div
        key={Math.random()} // Unique key for each bubble
        className={styles.bubble}
        style={{ left, top, width: size, height: size }}
      />
    );
  };

  const bubbles = Array.from({ length: numBubbles }, createRandomBubble);
  const properties = [
    { id: 1, name: "Flat and Apartments", location: "Aarya Realities offers a variety of spacious and elegantly appointed flats and apartments to suit your lifestyle needs, where luxury meets convenience in every detail. Our thoughtfully designed flats and apartments offer the perfect blend of style, comfort, and functionality.", image: house1 },
    { id: 2, name: "Commercial Projects", location: "From office buildings to retail spaces, Aarya Realities specializes in commercial construction projects of all sizes. Our experienced team will manage every aspect of your project, from initial planning to final construction, with a focus on efficiency, quality, and safety.    ", image: house2 },
    { id: 3, name: "Independant Banglows", location: "Aarya Realities represents the pinnacle of exclusive living in City. Each of our independent bungalows is a testament to timeless architecture, modern design, and uncompromising quality.", image: house3 },
    // Add more properties here
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show 2 cards on smaller screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show 1 card on mobile devices
        },
      },
    ],
  };

  return (
    <div className={`${styles.featureContainer} ${styles.bubblesContainer}`}>
      {bubbles}
      <div className={styles.feature}>
        <h2 className={styles.heading}>Featured real estate by Aarya</h2>
        <Slider {...settings}>
          {properties.map((property) => (
            <div key={property.id}>
              <div className={styles.card}>
                <img
                  src={property.image}
                  alt={property.name}
                  className={styles.image}
                />
                <div className={styles.details}>
                  <h3 className={styles.name}>{property.name}</h3>
                  <p className={styles.location}>{property.location}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Feature;
