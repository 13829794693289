import React from 'react';
import TeamMember from '../../TeamMember/TeamMember'; // Import the TeamMember component
import styles from './Team.module.css'; // Import the CSS module
import amit from '../../../assets/images/amit.jpg'; // Import the image
import arvind from '../../../assets/images/amitkedad.jpg'; // Import the image

const Team = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Our Team</h1>
      <div className={styles.row}>
        <div className={`${styles.col} ${styles.colMd4} ${styles.colSm6}`}>
          <TeamMember
            name="Arvind Thanekar"
            role="Founder"
            description="Arvind Thanekar, our esteemed founder and patriarch, has been a driving force in the construction industry for over 18+ years.
            With a solid foundation built on hard work, integrity, and craftsmanship, Arvind thanekar has earned a reputation for excellence and reliability.
            His vast experience spans across a diverse range of projects, from residential homes to commercial developments, leaving an indelible mark of quality and precision.
            
            From initial concept to final execution, we handle every phase of the construction process with meticulous attention to detail, ensuring that each project meets the highest standards of quality and safety."
            image={arvind}
          />
        </div>

        <div className={`${styles.col} ${styles.colMd4} ${styles.colSm6}`}>
          <TeamMember
            name="Amit Thanekar"
            role="Civil engineer"
            description="Amit Thanekar, the next generation of leadership, brings a fresh perspective and innovative thinking to the table.
            With a background as Er.Civil engineer, Amit Thanekar is well-equipped to lead Aarya Realities into the future.
            He shares his father's passion for construction and is committed to upholding the family legacy of excellence while embracing modern technologies and sustainable practices.
            Amit Thanekar began his journey in construction 10 years ago,over the years, He honed his civil skills, , and gained invaluable experience in various facets of the industry.
            His hands-on approach, coupled with a deep understanding of construction principles with latest techniques and skills that could help to sets the standard for our company's operations."
            image={amit}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;
