import React from 'react';
import styles from './ProjectModal.module.css';

const ProjectModal = ({ project, onClose }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <span className={styles.closeButton} onClick={onClose}>
          &times;
        </span>
        <h2 className={styles.modalTitle}>{project.name}</h2>
        <img src={project.image} alt={project.name} className={styles.modalImage} />
<br/>
<img src={project.isometric} alt={project.name} className={styles.modalImage} />
        <p className={styles.modalDetails}>{project.details}</p>
      </div>
    </div>
  );
};

export default ProjectModal;