import React, { useState } from 'react';
import styles from './UpcomingProjects.module.css';
import project1Image from '../../assets/images/images/PHOTO-2024-04-01-17-55-24.jpg';
import project2Image from '../../assets/images/whySection1.png';

// Modal component for Project 1
const Project1Modal = ({ onClose }) => (
  <div className={styles.modal}>
    <div className={styles.modalContent}>
    <div className={styles.scrollableContent}>
      <h2>Aarya Eminence</h2>
      <p>
      <h2>Discover Extraordinary Living: Additional Amenities at Aarya Eminence</h2>
  <p>At Aarya Eminence, we believe in redefining modern living by offering not just homes, but complete lifestyle experiences. In addition to luxurious residences, our project boasts a wide array of exceptional amenities designed to enhance your comfort, convenience, and well-being. Let’s explore some of the extraordinary features that await you:</p>
  <ul>
    <li><strong>State-of-the-Art Fitness Center:</strong> Stay fit and healthy with our cutting-edge fitness center equipped with the latest exercise machines and facilities.</li>
    <li><strong>Tranquil Green Spaces:</strong> Relax and unwind in the serene green spaces that provide a peaceful retreat from the hustle and bustle of city life.</li>
    <li><strong>Community Hall / Get-together Area:</strong> Foster a sense of community and socialize with neighbors in our spacious community hall and get-together area.</li>
    <li><strong>24/7 Security and Surveillance:</strong> Enjoy peace of mind knowing that your safety and security are our top priorities, with round-the-clock security and surveillance systems in place.</li>
  </ul>
            </p> 
             <p><strong>Conclusion:</strong> At Aarya Eminence, we go above and beyond to offer you a lifestyle that exceeds expectations. From world-class amenities to meticulous attention to detail, every aspect of our project is designed to elevate your living experience to new heights. Welcome home to a life of luxury, comfort, and unparalleled convenience.</p>      
      <button onClick={onClose}>Close</button>
    </div>
    </div>
  </div>
);

// Modal component for Project 2
const Project2Modal = ({ onClose }) => (
  <div className={styles.modal}>
    <div className={styles.modalContent}>
    <div className={styles.scrollableContent}>
      <h2>Eco-Friendly Villas</h2>
      <p>
        Discover the perfect balance between luxury and sustainability with our eco-friendly villas. Built with the latest green technologies and featuring beautiful natural surroundings, these villas offer a harmonious living experience.
      </p>
      <button onClick={onClose}>Close</button>
    </div>
    </div>
  </div>
);

const UpcomingProjects = () => {
  const [project1ModalVisible, setProject1ModalVisible] = useState(false);
  const [project2ModalVisible, setProject2ModalVisible] = useState(false);

  const openProject1Modal = () => setProject1ModalVisible(true);
  const closeProject1Modal = () => setProject1ModalVisible(false);
  const openProject2Modal = () => setProject2ModalVisible(true);
  const closeProject2Modal = () => setProject2ModalVisible(false);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Upcoming Projects</h2>
      <div className={styles.projectsContainer}>
        <div className={`${styles.project} ${styles.leftProject}`}>
          <div className={styles.imageContainer}>
            <img src={project1Image} alt="Project 1" className={styles.projectImage} />
          </div>
          <div className={styles.projectDetails}>
            <h3 className={styles.projectTitle}>Aarya Eminence</h3>
            <p className={styles.projectDescription}>
              Experience the epitome of luxury living in our upcoming apartment complex. Featuring state-of-the-art amenities, elegant architecture, and unparalleled views, these apartments are the ultimate in modern living.
            </p>
            <button className={styles.learnMoreButton} onClick={openProject1Modal}>Learn More</button>
          </div>
        </div>
        <div className={`${styles.project} ${styles.rightProject}`}>
          <div className={styles.projectDetails}>
            <h3 className={styles.projectTitle}>Eco-Friendly Villas</h3>
            <p className={styles.projectDescription}>
              Discover the perfect balance between luxury and sustainability with our eco-friendly villas. Built with the latest green technologies and featuring beautiful natural surroundings, these villas offer a harmonious living experience.
            </p>
            <button className={styles.learnMoreButton} onClick={openProject2Modal}>Learn More</button>
          </div>
          <div className={styles.imageContainer}>
            <img src={project2Image} alt="Project 2" className={styles.projectImage} />
          </div>
        </div>
      </div>
      {project1ModalVisible && <Project1Modal onClose={closeProject1Modal} />}
      {project2ModalVisible && <Project2Modal onClose={closeProject2Modal} />}
    </div>
  );
};

export default UpcomingProjects;
