import React from "react";
import styles from "./AboutSection.module.css";

const AboutSection = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>About Us</h1>
      <div className={styles.content}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardHeading}>Introduction</h2>
            <div className={styles.headerLine}></div>
          </div>
          <p className={styles.cardText}>
            Welcome to Aarya Realities, where passion for construction meets
            unwavering commitment to quality. At the helm of our company is
            Arvind Thanekar , a visionary leader with a profound dedication to
            shaping dreams into reality. With more than 18+ years of experience
            in the construction industry, Arvind Thanekar brings expertise,
            integrity, and a relentless pursuit of excellence to every project
            we undertake. We're proud to be a family-owned and operated
            construction firm, where expertise meets tradition, and innovation
            blends seamlessly with legacy. Meet our dynamic duo: Arvind Thanekar
            and Amit Thanekar, partners who bring a wealth of experience, a
            shared passion for construction, and a deep commitment to delivering
            exceptional results.Their complementary strengths and collaborative
            approach create a synergy that drives our company forward, ensuring
            that every project we undertake is executed with precision,
            integrity, and unparalleled craftsmanship.
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardHeading}>Our commitment</h2>
            <div className={styles.headerLine}></div>
          </div>
          <p className={styles.cardText}>
            At Aarya Realities, we're proud to carry forward a legacy of
            excellence that spans generations. From Arvind Thanekar's
            time-honored craftsmanship to Amit Thanekar's innovative thinking,
            our firm embodies the best of both worlds — tradition and
            innovation. Together, we're committed to exceeding expectations,
            building lasting relationships, and leaving a legacy that will stand
            the test of time. They believe in giving back and are involved in
            various charitable initiatives, volunteer projects, and mentorship
            programs, embodying the values of integrity, generosity, and
            compassion.
            <br />
            Moreover, we value open communication and collaboration with our
            clients, ensuring their visions are brought to life seamlessly. Our
            goal is to forge lasting relationships built on trust, transparency,
            and mutual respect.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
