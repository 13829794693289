import React,{useEffect} from 'react';
import styles from './Footer.module.css';
import { Link, useLocation } from 'react-router-dom';
import  logo  from '../../../assets/logo_white.png';

const Footer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
 
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]); // Triggers effect whenever pathname changes

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.logo}>
          <img src={logo} alt="Aarya Realities Logo" width={170} height={100} />
        </div>
        <br/>
        <div className={styles.contactInfo}>
          <div className={styles.address}>
            <i className="fas fa-map-marker-alt"></i>
            <span>
            Address 1 : PLOT NO. 5, Aarya Residency 3,<br/> Bhumi Abhilekh Society,
Near Dabha Naka, Nagpur, Maharashtra 440023
<br/>
Address 2 : 101, Sanchayani Complex,<br/>
Trimurti Nagar, Nagpur - 440022<br/>
<br/>

            </span>
          </div>
          <div className={styles.phone}>
            <i className="fas fa-phone-alt"></i>
            <span><a href="tel:+919890876444">+91 9890876444</a></span>
          </div>
          <div className={styles.phone}>
            <i className="fas fa-phone-alt"></i>
          
<span><a href="tel:+919372307610">+91 9372307610</a></span>
          </div>
        </div>
        <div className={styles.links}>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/projects">Projects</Link></li>
     
          </ul>
        </div>
        <div className={styles.socialMedia}>
          <a href="#" className={styles.icon}><i className="fab fa-facebook-f"></i></a>
          <a href="#" className={styles.icon}><i className="fab fa-twitter"></i></a>
          <a href="#" className={styles.icon}><i className="fab fa-instagram"></i></a>
          <a href="#" className={styles.icon}><i className="fab fa-linkedin"></i></a>
        </div>
      </div>
      <div className={styles.copyRight}>© 2024 Aarya Realities. All rights reserved.</div>
    </footer>
  );
};

export default Footer;
