//  create react component named about 
import React from 'react';
import styles from './About.module.css';
import AboutHeroSection from '../../components/AboutHeroSection/AboutHeroSection';
import Team from '../../components/ui/Team/Team';
import ContactForm from '../../components/ContactForm/ContactForm';
import AboutSection from '../../components/AboutSection/AboutSection';

const About = () => {
  return (
    <div className={styles.container}>
   
     <AboutHeroSection />
     <AboutSection/>
     <Team/>
     
    </div>
  );
}
export default About;