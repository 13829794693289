import React from "react";
import styles from "./Services.module.css";
import ServiceBox from "./ServiceBox";
import { FaHammer } from "react-icons/fa";
import { FaWrench } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa";

const Services = () => {
  const numBubbles = 30; // Number of bubbles
  const bubbleSize = 20; // Bubble diameter in pixels

  const createRandomBubble = () => {
    const left = Math.random() * 100 + "vw";
    const top = Math.random() * 100 + "vh";
    const size = Math.random() * bubbleSize + "px";

    return (
      <div
        key={Math.random()} // Unique key for each bubble
        className={styles.bubble}
        style={{ left, top, width: size, height: size }}
      />
    );
  };

  const bubbles = Array.from({ length: numBubbles }, createRandomBubble);
  return (
    <div className={styles.container}>
      {bubbles}
    <div className={styles.services}>
      <h2 className={styles.header}>Services</h2>
      <div className={styles.serviceContainer}>
        <div className={styles.service}>
          <ServiceBox
            title="Construction"
            description="Tailored to Your Vision
            From concept to completion, we bring your dream home to life with precision craftsmanship and attention to detail."
            color="blue" // You can use 'orange', 'blue', or 'green'
            icon={<FaHammer/>}
          />
        </div>
        <div className={styles.service}>
          <ServiceBox
            title="Renovation and Remodeling"
            description="Transform your existing space into a modern masterpiece. Our experts handle everything from minor upgrades to major renovations."
            color="blue" // You can use 'orange', 'blue', or 'green'
            icon={<FaWrench/>}
          />
        </div>
        <div className={styles.service}>
          <ServiceBox
            title="Consultation and Advisory Services"
            description="Need expert advice? Our knowledgeable team provides professional guidance on all aspects of construction and real estate."
            color="blue" // You can use 'orange', 'blue', or 'green'
            icon={<FaLightbulb/>}
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default Services;
