import React, { useState } from 'react';
import styles from './ProjectsShowcase.module.css';
import ProjectModal from '../../components/ProjectModal/ProjectModal';
import UpcomingProjects from '../../components/UpcomingProjects/UpcomingProjects';
import project1Image from '../../assets/images/images/gopal pushpa revised 02(28-09-20).jpg';
import project2Image from '../../assets/images/images/hazaripahad_04_revised.jpg';
import project1ImageIso from '../../assets/images/images/gopal pushpa isometric01(1st-3rd floor) revised(08-11-20).jpg';
import project2ImageIso from '../../assets/images/images/hazaripahad isometric 01 revised(29-11-21).jpg';
import ProjectServices from '../../components/ProjectServices/ProjectServices';

const ProjectsShowcase = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const projects = [
    {
      id: 1,
      name: 'Aarya Glory',     
      description: 'This is a brief description of Aarya Glory.',
      image: project1Image,
      details: 'Aarya Glory, Dabha Ring road Project',
      isometric: project1ImageIso,
    },
    {
      id: 2,
      name: 'Aarya Harsh',
      description: 'This is a brief description of Aarya Harsh.',
      image: project2Image,
      details: 'Aarya Harsh, Didhori Ring road Project ',
      isometric: project2ImageIso,
    },
    // Add more projects here
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Our Projects</h1>
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={handleSearch}
          className={styles.searchInput}
        />
      </div>
      <div className={styles.projectsGrid}>
        {filteredProjects.map((project) => (
          <div
            key={project.id}
            className={styles.project}
            onClick={() => handleProjectClick(project)}
          >
            <img src={project.image} alt={project.name} className={styles.projectImage} />
            <h3 className={styles.projectName}>{project.name}</h3>
            <p className={styles.projectDescription}>{project.description}</p>
          </div>
        ))}
      </div>
      <ProjectServices/>
      <UpcomingProjects/>
      {selectedProject && (
        <ProjectModal
          project={selectedProject}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default ProjectsShowcase;