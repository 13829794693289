import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import styles from './Navbar.module.css';
import logo from '../../../assets/logo-1.png';

const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <img src={logo} alt="Aarya Realities Logo" width={130} height={80} />
      </div>
      <ul className={styles.navLinks}>
        {/* Replace anchor tags with Link components */}
        <li><Link to="/" className={`${styles.navLink} ${styles['poppins-semibold']}`}>Home</Link></li>
        <li><Link to="/about" className={`${styles.navLink} ${styles['poppins-semibold']}`}>About</Link></li>
        <li><Link to="/projects" className={`${styles.navLink} ${styles['poppins-semibold']}`}>Projects</Link></li>
      </ul>
      {/* Replace the anchor tag with a Link component */}
      <a to="/contact" className={`${styles.ctaButton} ${styles['poppins-semibold']}`}>Contact Us</a>
    </nav>
  );
};

export default Navbar;
