// Services.js
import React from "react";
import styles from "./ProjectServices.module.css";

const ProjectServices = () => {
  return (
    <div className={styles.servicesContainer}>
      <h2 className={styles.heading}>Services</h2>
      <div className={styles.services}>
        <div className={`${styles.service} ${styles.fadeIn}`}>
          <div className={styles.iconContainer}>
            <i
              className={`${styles.icon} fas fa-chart-line ${styles.iconAnimation}`}
            ></i>
          </div>
          <h3 className={styles.serviceTitle}>Advisory</h3>
          <p className={styles.serviceDescription}>
            Offering strategic guidance to real estate clients, leveraging
            market insights and industry expertise to navigate complex decisions
            and achieve optimal outcomes.
          </p>
        </div>
        <div className={`${styles.service} ${styles.fadeIn}`}>
          <div className={styles.iconContainer}>
            <i
              className={`${styles.icon} fas fa-briefcase ${styles.iconAnimation}`}
            ></i>
          </div>
          <h3 className={styles.serviceTitle}>Management</h3>
          <p className={styles.serviceDescription}>
            Efficiently overseeing property operations, maximizing asset
            performance, and implementing tailored strategies to enhance value
            and tenant satisfaction.
          </p>
        </div>
        <div className={`${styles.service} ${styles.fadeIn}`}>
          <div className={styles.iconContainer}>
            <i
              className={`${styles.icon} fas fa-chart-area ${styles.iconAnimation}`}
            ></i>
          </div>
          <h3 className={styles.serviceTitle}>Valuation</h3>
          <p className={styles.serviceDescription}>
            Expertly assessing the worth of real estate assets, providing
            accurate appraisals crucial for acquisitions, sales, and portfolio
            management decisions.
          </p>
        </div>
        <div className={`${styles.service} ${styles.fadeIn}`}>
          <div className={styles.iconContainer}>
            <i
              className={`${styles.icon} fas fa-home ${styles.iconAnimation}`}
            ></i>
          </div>
          <h3 className={styles.serviceTitle}>Leasing</h3>
          <p className={styles.serviceDescription}>
            Facilitating lease agreements, negotiating terms, and cultivating
            tenant relationships to optimize occupancy rates and maximize rental
            income.
          </p>
        </div>
        <div className={`${styles.service} ${styles.fadeIn}`}>
          <div className={styles.iconContainer}>
            <i
              className={`${styles.icon} fas fa-dollar-sign ${styles.iconAnimation}`}
            ></i>
          </div>
          <h3 className={styles.serviceTitle}>Capital</h3>
          <p className={styles.serviceDescription}>
            Strategically managing real estate investment portfolios, optimizing
            capital deployment, and sourcing financing solutions to support
            growth and expansion initiatives.
          </p>
        </div>
        <div className={`${styles.service} ${styles.fadeIn}`}>
          <div className={styles.iconContainer}>
            <i
              className={`${styles.icon} fas fa-chart-line ${styles.iconAnimation}`}
            ></i>
          </div>
          <h3 className={styles.serviceTitle}>Investment</h3>
          <p className={styles.serviceDescription}>
            Identifying prime real estate opportunities, conducting thorough
            market analysis, and executing investment strategies to deliver
            attractive risk-adjusted returns for investors.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectServices;
