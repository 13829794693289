import React from 'react';
import styles from './HeroSection.module.css';

const HeroSection = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.content}>
        <h1 className={styles.title}>The dream home you deserve is here</h1>
        <p className={styles.description}>Building Dreams, One Home at a Time. Find Your Perfect Place with <span className={styles.brand}> Aarya Realities </span>. </p>
        <div className={styles.buttons}>
          <button className={styles.callUsButton}><a href="tel:+919890876444">Call Us </a></button>
          {/* <button className={styles.learnMoreButton}>Learn More</button> */}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
