import React from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import WhySection from "../../components/WhySection/WhySection";
import Feature from "../../components/Feature/Feature";
import Services from "../../components/Services/Services";
import ContactForm from "../../components/ContactForm/ContactForm";
import WhyChoose from "../../components/WhyChoose/WhyChoose";
const Home = () => {
  return (
    <>
      <HeroSection />
     
      <WhyChoose/>
      {/* <WhySection /> */}
      <Feature />
      <Services />
      <ContactForm />
    </>
  );
};
export default Home;
