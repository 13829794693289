import React, { useState } from 'react';
import styles from './ContactForm.module.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Get form data
    
  
    // Construct email body
    const body = `Name: ${formData?.name}%0D%0AEmail: ${formData?.email}%0D%0AMessage: ${formData?.message}`;
  
    // Open default email client with pre-filled details in a new tab
    window.open(`mailto:your@email.com?subject=Contact Form Submission&body=${encodeURIComponent(body)}`);
  
    // Reset form data
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };
  

  return (
    <div className={styles.container}>
      <div className={styles.questionContainer}>
        <h2 className={styles.questionHeading}>Do you have any questions?</h2>
        <p className={styles.questionText}>
        "Have questions or need further clarification? Don't hesitate to reach out to our friendly team—we're here to help!"        </p>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Your name"
            className={styles.input}
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            placeholder="Email address"
            className={styles.input}
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <textarea
            placeholder="Your message"
            className={styles.textarea}
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
          <button type="submit" className={styles.submitButton}>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
