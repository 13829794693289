import React,{useRef,useEffect} from 'react';
import styles from './WhyChoose.module.css';
import backgroundImage from '../../assets/images/whyChoose.svg';
import BubblesBackground from '../ui/BubblesBackground/BubblesBackground';

const WhyChoose = () => {
  const numBubbles = 30; // Number of bubbles
  const bubbleSize = 20; // Bubble diameter in pixels

  const createRandomBubble = () => {
    const left = Math.random() * 100 + 'vw';
    const top = Math.random() * 100 + 'vh';
    const size = Math.random() * bubbleSize + 'px';

    return (
      <div
        key={Math.random()} // Unique key for each bubble
        className={styles.bubble}
        style={{ left, top, width: size, height: size }}
      />
    );
  };

  const bubbles = Array.from({ length: numBubbles }, createRandomBubble);
  return (
<div className={`${styles.whyChoose} ${styles.bubblesContainer}`}>
     {/* <div ref={backgroundRef} className={styles.background} style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat' }}> */}
    {bubbles}
      <div className={styles.container}>
        <h2 className={styles.title}>Why Choose Us?</h2>
        <div className={styles.cardWrapper}>

          <div className={styles.cards}>
            <div className={styles.card}>
              <div className={styles.icon}></div>
              <h3 className={styles.subtitle}>Quality and Craftsmanship</h3>
              <p> Quality is at the core of everything we do. We are committed to delivering superior craftsmanship and ensuring that every detail of your project meets the highest standards of excellence.</p>
            </div>
            <div className={styles.card}>
              <div className={styles.icon}></div>
              <h3 className={styles.subtitle}>Transparency and Communication</h3>
              <p>At Aarya Realities, we believe in transparent communication. We keep our clients informed at every stage of the construction process, providing regular updates and addressing any concerns promptly.</p>
            </div>
            <div className={styles.card}>
              <div className={styles.icon}></div>
              <h3 className={styles.subtitle}>Customer Satisfaction</h3>
              <p>Our ultimate goal is customer satisfaction. We go above and beyond to exceed our clients' expectations and ensure that their vision becomes a reality. Your satisfaction is our top priority, and we won't rest until you are completely satisfied with the final result.</p>
            </div>
          </div>
        </div>
        </div>
      </div>
    // </div>
  );
};

export default WhyChoose;
