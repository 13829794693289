import React from 'react';
import styles from './TeamMember.module.css'; // Import the CSS module

const TeamMember = ({ name, role, description, image }) => {
  return (
    <div className={styles.ourTeam}>
      <img src={image} alt={name} />
      <div className={styles.teamContent}>
        <h3 className={styles.title}>{name}</h3>
        <span className={styles.post}>{role}</span>
        <p className={styles.description}>{description}</p>
        <ul className={styles.social}>
          <li><a className={`${styles.socialLink} ${styles.faFacebook}`} href="#"></a></li>
          <li><a className={`${styles.socialLink} ${styles.faTwitter}`} href="#"></a></li>
          <li><a className={`${styles.socialLink} ${styles.faGooglePlus}`} href="#"></a></li>
          <li><a className={`${styles.socialLink} ${styles.faYoutube}`} href="#"></a></li>
        </ul>
      </div>
    </div>
  );
};

export default TeamMember;
