import React from 'react';
import styles from './ServiceBox.module.css';

const ServiceBox = ({ title, description, color,icon }) => {
  const boxClassName = `${styles.serviceBox} ${styles[color]}`;
  
  return (
    <div className={boxClassName}>
      <div className={styles['service-icon']}>
        <span className={styles.icon}> {icon} </span>
      </div>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default ServiceBox;
